import React, {useEffect, useMemo, useRef, useState} from 'react';
import _ from 'lodash';
import {useDispatch, useSelector} from "react-redux";
import {Actions} from "../../core";
import {EventPopup} from "..";
import {isNotEmpty} from "../../core/core/utils";
import Moment from 'moment'
import {TimeOut} from "../../core/core/utils/TimeOut";
import {useHistory} from "react-router-dom";

export const Events = ({id,page,handleTabs,checkActive,onSelectEvent}) =>{
  const history = useHistory();
  const ref= useRef(null)
  let [index,setIndex]=useState(20)
	const dispatch = useDispatch();
	const nights = useSelector(state=>state.Store.Event);
	useEffect(()=>{
	  if(page==='nights'){
	    if(id==="horoom"){
	      dispatch(Actions.Event.getEvent(id))
      }else{
            dispatch(Actions.Event.getEvents());

        }
      if(ref.current){
        ref.current.addEventListener("scroll", function(e){
          TimeOut.clear();
          TimeOut.set(()=>{
            setIndex(index+=10)
          },100)
        })
      }
    }
    return ()=>{
      if(page==='nights'){
        ref.current.removeEventListener("scroll", ()=>{
          setIndex(20)
        })
      }
    }
	},[page,id])

  const data = useMemo(() => {
    return nights.filter((v,k)=>k<=index)

  }, [nights,index]);

  const renderTitle=(post)=>{
      if(post.id<=398){
        return post?.title.substring(0,post?.title.length-17).concat(" // ", Moment(post.event_start).format("D MMM // YYYY"))
      }
      return  post.title.concat(" // ", Moment(post.event_start).format("D MMM // YYYY"));
  }
   const renderEvents = () => {
      return (
        (isNotEmpty(id) && id !=='calendar' && id !=="horoom")?  <EventPopup id={id} page={page}/>:
          <ul className="card-ul">
            {
              _.map(data,(data, index)=>{
                return <Event key={index} index={index}  data={data} onClick={()=>{
                  if(data.provider==="bassiani") {
                    history.push(data.url)

                  }else {
                    window.location.href=data.url
                  }
                  //onSelectEvent("nights",data.id)
                }}/>
              })
            }
          </ul>
      )
  }
  const renderCalendar=()=> {
    return  <ul id="calendar-list" className="calendar-list">
      {
        _.map(nights,(data, index)=>{
          return   <li key={index} index={index}  data={data} onClick={()=>{
            if(data.provider==="bassiani") {
              history.push(data.url)
            }else {
              window.location.href=data.url
            }
/*
            onSelectEvent("nights",data.id)
*/
          }} >
            <p style={{textTransform:'uppercase'}}>{renderTitle(data)} </p>
            <p className="marque-text" >
								<span>
                  {data.sub_title}
								</span>
            </p>
          </li>
        })
      }
    </ul>;
  }


  return  (
    <li id="events"  className={`${checkActive('nights')}`}  >
      <div className="item-head" onClick={()=>handleTabs("nights")}><span>Nights</span></div>
      <div className="item-body" ref={ref}>
        <div id="calendar" className="calendar" onClick={()=>onSelectEvent("nights","calendar")}>
          Program
        </div>
        {
          (isNotEmpty(id) && id==="calendar")? renderCalendar(): renderEvents()
        }
      </div>
    </li>
	)
}

const Event = ({onClick,data}) =>{
const ref = useRef(null);
const titleRef= useRef(null)
 const [divWidth, setDivWidth]= useState(0)
 const [titleWidth, setTitleWidth]= useState(0)
  useEffect(()=>{
    setDivWidth(ref.current.clientWidth);
  },[ref])
  useEffect(()=>{
    setTitleWidth(titleRef.current.clientWidth);
  },[titleRef])
  return <li className="card-li" onClick={()=>onClick()}>
		<div className="card-img" style={{backgroundImage: `url(${data.main_image_path})`}}> </div>
		<div className="card-title" ref={ref}>
			<span>
				{data.event_start?Moment(data.event_start).format("D MMMM // YYYY"):data.sub_title}
			</span>
      <span ref={titleRef} className={`${/*divWidth< titleWidth*/ 1===1? 'long-text':""}`}>{data.title.concat(` // `,data.title ," // ",data.title," // ",data.title," // ",data.title," // ",data.title," // ")} {divWidth< titleWidth ? data.title:""}</span>
    </div>
	</li>
}
