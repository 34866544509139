import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Actions,Request} from "../../core";
import {useDispatch,useSelector} from "react-redux";
import _ from 'lodash';
import {PodcastPopup} from "./PodcastPopup";
import {isNotEmpty} from "../../core/core/utils";
import {TimeOut} from "../../core/core/utils/TimeOut";
import {ShareBtn} from "../Share/ShareBtn";
export const Podcasts = ({handleTabs,checkActive,onSelectItem, onDeselect,id,page}) =>{

	const dispatch = useDispatch();
	const data = useSelector(state=>state.Store.PodCast);
	const [selected, setSelected] = useState('');
  let [index,setIndex]=useState(20)
  const ref= useRef(null)
  useEffect(()=>{

	  if(page==='podcasts'){
      setSelected(id);
    }
  },[id])
	useEffect(()=>{
    if(page==="podcasts" && _.size(data)===0){
      dispatch(Actions.PodCast.getPodCasts());
    }

    if(ref.current){
      ref.current.addEventListener("scroll", function(e){
        TimeOut.clear();
        TimeOut.set(()=>{
          setIndex(index+=5)
        },100)
      })
    }
    return ()=>{
      ref.current.removeEventListener("scroll", ()=>{
        setIndex(20)
      })

    }
  },[page])
  const getData = useMemo(() => {
    return data.filter((v,k)=>k<=index)

  }, [data,index]);
	return (
		<>
      <li id="podcast"  className={`${checkActive('podcasts')}`}  >
        <div className="item-head" onClick={()=>handleTabs("podcasts")}><span>Podcast</span></div>
				{
					<div className="item-body" ref={ref}>

						<ul className="collapse-ul">
							{
								_.map(getData, (value, index)=>{
									return (
                  <li  className={`collapse-li ${selected.toString() === value.id.toString() ? "active":""}`} key={index} onClick={()=>{
                    if(selected===value.id.toString()){
                      handleTabs("podcasts")
                    }else{
                      onSelectItem(value.id)
                    }
                  }}>
                    {
                      (isNotEmpty(selected) && page==='podcasts' && selected.toString() === value.id.toString())?<PodcastPopup  id={selected} />: null
                    }
                    <div className="li-head" >
                      <p>{value.row_title}</p>
                      <p >
                        <span>{value.title}</span>
                        <span>{value.sub_title}</span>
                      </p>

                    </div>
                  </li>)
								})
							}

						</ul>
					</div>
				}
			</li>
		</>
	)
}



