import React, {useEffect, useState} from "react";
import {Actions} from "../../core";
import  _ from 'lodash';
import {CHANGE_LANGUAGE} from "../../core/core/store/actionTypes";
import {useDispatch,useSelector} from "react-redux";
import {ShareBtn} from "../Share/ShareBtn";
export const Rules = ()=>{
    const dispatch= useDispatch()

    const [rules,setRules]=useState(null)
    const lang = useSelector(state=>state?.Lang);
    useEffect(() => {
        Actions.User.rules().then(response=>{
            if(response?.data?.status===1){
                setRules(response?.data?.data)
            }else{
                setRules(null)
            }
        })
    }, []);


    return (
        <div id="terms-box" className="terms-box" >
            <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between',marginBottom:20}}>
                <div><span style={{fontSize:26}}>HOUSE RULES & AWARENESS</span></div>
                <div style={{flexDirection:'row',display:'flex',alignItems:'center'}}>
                    <div style={{display:'flex',marginRight:20,paddingTop:5}}>
                        <ShareBtn
                            host={window.location.origin}
                            description={"Individuals in the club accept the validity of these rules by entering the area covered by the house rules."}
                            image={window.location.origin+"/assets/share/share.png"}
                            title={"House Rules & Awareness"}
                            url={window.location.pathname}
                            icon={
                                <img src={require('../../assets/icons/icon-share.svg').default}   style={{cursor:'pointer',width:26}} alt=""/>
                            }
                        />
                    </div>
                    <div className="popup-lang" style={{cursor:'pointer',fontSize:26}} onClick={()=>dispatch({
                        type:CHANGE_LANGUAGE,
                        payload: lang==='ka'? 'en':'ka'
                    })}>{lang==='ka'? 'EN':'KA'}</div>
                </div>

            </div>
            <div dangerouslySetInnerHTML={{__html:lang==='ka'?rules?.data:rules?.data_en}} className={"house-rules"}  />

        </div>
     )
}

/*


<div id="terms-box" className="terms-box">
    {/!*   <div className="white-terms">
                ონლაინ ბილეთის შეძენა შეუძლიათ მხოლოდ რეგისტრირებულ და ვერიფიცირებულ პირებს;
                <br/> ონლაინ ბილეთის ყიდვა შესაძლებელია ვიზა და მასტერქარდის პლასტიკური ბარათებით;
                <br/> ვერიფიცირებულ პირს მხოლოდ ერთი ონლაინ ბილეთის შეძენა შეუძლია;
                <br/> გაყიდული ონლაინ ბილეთი უკან არ ბრუნდება;
                <br/> დაკარგული ნივთის შემთხვევაში შეავსეთ ვებგვერდზე მოცემული ფორმა, გაითვალისწინეთ გარდერობის გარეთ დატოვებულ ნივთებზე ადმინისტრაცია პასუხისმგებლობას არ იღებს.

                <br/> საკლუბო ღამეზე დასასწრებად აუცილებლად უნდა წარმოადგინოთ:
                <br/>&#8226; &nbsp; ონლაინ ბილეთის უნიკალური კოდი.
                <br/>&#8226; &nbsp; პირადობის დამადასტურებელი მოწმობა (მხოლოდ ფიზიკური დოკუმენტი, არ მიიღება ასლი ან ტელეფონში არსებული ფოტო)

                <br/>&#8226; &nbsp; ვაქცინაციის ან გამოჯანმრთელების EU Digital COVID Certificate.

                <br/>
                <br/> Online ticket can be purchased for only verified users;
                <br/> Only visa and mastercard credit cards are available to pay for the online ticket;
                <br/> Verified user can purchase a maximum 1 online ticket;
                <br/> Online ticket is non-refundable.
                <br/> For the lost items please fill in the Lost & Found form;
                <br/> We are not responsible for the items which are lost outside the cloakroom.
                <br/> In order to enter the club, you have to present the following:
                <br/>&#8226; &nbsp; Online Ticket QR code.
                <br/>&#8226; &nbsp; It is mandatory to present your ID or Passport with your ticket.  [no scan, no photocopy] ID needs to be valid. Out of date forms of ID will not be accepted. No ID, no entry!

                <br/>&#8226; &nbsp; Proof of vaccination or Recovery Only with EU Digital COVID Certificate.



              </div>
              <div className="red-terms">
							<span className="red-terms-title">
								გაეცანით BASSIANI / HOROOM-ის ზოგად წესებს:
								<br/>REMEMBER THAT IT IS STRICTLY FORBIDDEN TO:
							</span>
                <ul className="red-terms-list">
                  <li className="caut-lght">
									<span>
										ტელეფონის ეკრანის და
										<br/>  სანათის გამოყენება.
										<br/> Use telephone screen light or flashlight.
									</span>
                  </li>
                  <li className="caut-weap">
									<span>
										ტერიტორიაზე ცივი იარაღების,
										<br/> ფეთქებადი და აალებადი ნივთების შეტანა.
										<br/> Bringing flammable/explosive materials.
									</span>
                  </li>
                  <li className="caut-pic">
									<span>
										ფოტო და ვიდეო გადაღება.
										<br/> Please respect our no photo / Video policy.
									</span>
                  </li>
                  <li className="caut-tick">
									<span>
										ონლაინ ბილეთის სხვისთვის გადაცემა.
										<br/> Giving out tickets to another person.
									</span>
                  </li>
                  <li className="caut-age">
									<span>
										18 წლამდე პირების დაშვება.
										<br/> Entrance of persons under 18.
									</span>
                  </li>
                  <li className="caut-smk">
									<span>
										კლუბის შიდა ტერიტორიაზე თამბაქოს მოხმარება.
										<br/> Smoking is prohibited inside the club.
									</span>
                  </li>
                </ul>
              </div>*!/}
</div>
*/
