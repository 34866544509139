import React, {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Actions} from '../../core'
import {PasswordReset} from "../index";
import {Link, useHistory} from "react-router-dom";

export const LoginPopup = () => {
  const history = useHistory()

  const ref = useRef();
  const dispatch = useDispatch();
  const login = useSelector(state => state.Store.PopupWindow.modals.login);
  const reset = useSelector(state => state.Store.PopupWindow.modals.passwordReset);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false); // New state for loading

  if (reset) {
    return <PasswordReset />
  }

  return (
    login ?
      <form onSubmit={async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true when the form is submitted

        window.grecaptcha.execute('6Lc48aEdAAAAAPIKRiPrfZK-zFzlGxbx54HXgI21', { action: 'submit' }).then(async (token) => {
          if (token) {
            const responseStatus = await dispatch(Actions.User.auth({ username: username, password: password, token: token }));
            setStatus(responseStatus);
            setLoading(false); // Set loading to false after the request completes
          } else {
            console.log(token);
            setLoading(false); // Set loading to false if token is not available
          }
        }).catch(() => {
          setLoading(false); // Set loading to false in case of an error
        });
      }}>
        <div className="log-in-sec">
          <div className="login-box">
            <p className="log-un-title">login</p>

            <label htmlFor="user-name-12" className={status === false ? "mistake" : ""}>
              {(status === false) ? "The username or password is incorrect" : "username"}
            </label>
            <input type="text" id="user-name-12" ref={ref} placeholder="username" value={username} onChange={e => setUsername(e.target.value)} />

            <label htmlFor="user-pasw">password</label>
            <input type="password" id="user-pasw" placeholder="password" value={password} onChange={e => setPassword(e.target.value)} />

            <div className="submit-btn">
              <input type="submit" value={loading ? "Loading..." : "Submit"} disabled={loading} />
            </div>

            <div className="lig-in-opt">
              <span onClick={() => dispatch({ type: 'POPUP_WINDOW', payload: true, modal: "passwordReset" })}>
                forgot password
              </span>
            </div>

            <div className="log-in-terms">
              <span>by logging in, you agree to <strong onClick={() => window.location.href = "/dark/contact/terms"}>bassiani's terms and conditions</strong></span>
              <a href="/user/register"><span>not registered yet ?</span></a>
            </div>
          </div>
        </div>
      </form>
      : null
  )
}
