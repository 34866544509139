import React, {useEffect, useState} from 'react';
import {isNotEmpty} from "../../core/core/utils";
import {useDispatch} from "react-redux";
import {CopyRight} from "../index";
import {LostAndFound} from "./LostAndFound";
import {Jobs} from "./Jobs";
import {Rules} from "./Rules";
import moment from "moment";


export const Contact = ({id,handleTabs,checkActive,onSelectItem,page})=>{
  const [selected, setSelected] = useState(null);

  const [index,setIndex]=useState(0)

  const dispatch = useDispatch();
  useEffect(()=>{
    setSelected(id)

    if(id==='' || id==='lostandfound'){
       setIndex(0)
    }else if(id==="jobs"){
      setIndex(1)
    }

  },[id])
  return  (
    <li id="contacts"  className={`${checkActive('contact')}`}  >
      <CopyRight/>
      <div className="item-head" onClick={()=>handleTabs("contact")}><span>Contact</span></div>
      <div className="item-body">
        <div id="terms" className="terms" onClick={() => {
          if (selected === 'house-rules') {
            handleTabs("contact")
          } else {
            onSelectItem('house-rules')
          }
        }}>
          HOUSE RULES & AWARENESS
        </div>
        {
          (page==='contact' && id==='house-rules')?
            <Rules/>:
            <div className="contact-box">
              <section className="left-side-sec">
                <div className="left-side-title" style={{padding:0}}>
                  <div style={{display:"flex",justifyContent:"space-between",width:"100%",height:'100%',alignItems:'center'}}>
                    <div style={{flex:1,cursor:'pointer', display:'flex',justifyContent:'center',color:`${index===0?'white':'#b2aeae'}`}} onClick={()=>{
                      onSelectItem("lostandfound")
                    }}>lost and found</div>
                    <div style={{width:1,background:'white',height:'100%'}}></div>
                    <div style={{display:'flex',flex:1,justifyContent:'center',cursor:'pointer',color:`${index===1?'white':'#b2aeae'}`}} onClick={()=>{
                      onSelectItem("jobs")
                    }}>jobs</div>
                  </div>
                </div>
                {
                  index ===0?<LostAndFound/>:
                    <Jobs />
                }
                {
                  index===0 && (
                    <div className="lost-condition">
                      <span>
                          Lost and Found working hours: Tuesday - Friday, 14:00 - 20:00;
                          Lost items won't be stored longer than two months. Items found and left unclaimed will be donated to a social enterprise supporting queer-feminist initiatives for sale after eight weeks.<br/>
                      </span>
                      <span style={{cursor:"pointer"}} onClick={()=>dispatch({type:'POPUP_WINDOW',payload:true,modal: "copyright"})}>
                          © {moment().format("YYYY")} bassiani
                      </span>
                    </div>
                  )
                }
              </section>
              <section className="right-side-sec">
                <div className="right-side-title">
                  <p>
                    <span>2, Akaki Tsereteli Ave., 0119 Tbilisi</span>
                    <span>info@bassiani.com</span>
                  </p>
                </div>
                <div className="right-side-img">
                  <iframe className="bassiani-map" style={{filter: "invert(1) hue-rotate(269deg) grayscale(1) brightness(1.5)"}} width="100%" height="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=100%25&amp;height=100%25&amp;hl=en&amp;q=Boris%20Paichadze%20Dinamo%20Arena+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"/>
                </div>
              </section>
            </div>
        }
      </div>
    </li>
  )
}
